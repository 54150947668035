export const DRIVER_TYPE_COOLMASTER = "CoolMaster";
export const DRIVER_TYPE_CORECENTER = "CoreCenter";
export const DRIVER_TYPE_GPIO = "GPIO";
export const DRIVER_TYPE_KNXIP = "KnxIP";
export const DRIVER_TYPE_KNXTP = "KnxTP";
export const DRIVER_TYPE_LOGO = "Logo";
export const DRIVER_TYPE_RS485 = "RS485";
export const DRIVER_TYPE_HUE = "HUE";
export const DRIVER_TYPE_MODBUS = "Modbus";
export const DRIVER_TYPE_COREOS_PANEL = "CoreOSPanel";

export const CONNECTION_TCP = 0;
export const CONNECTION_RTU = -1;
export const CONNECTION_RTU_OVER_TCP = 3;
export const CONNECTION_NONE = 4;

export const RS232 = 0;
export const RS485 = 1;

export const DRIVER_MODE_DRIVER = "DRIVER";
export const DRIVER_MODE_CLIENT = "CLIENT";

export const driverModes = [{ title: "driver", value: DRIVER_MODE_DRIVER }];

export const driverTypes = [
  { title: "CoolMaster Net", value: DRIVER_TYPE_COOLMASTER },
  { title: "Core Center", value: DRIVER_TYPE_CORECENTER },
  { title: "System I/O", value: DRIVER_TYPE_GPIO },
  { title: "KNXnet/IP", value: DRIVER_TYPE_KNXIP },
  { title: "KNX Twisted Pair", value: DRIVER_TYPE_KNXTP },
  { title: "Siemens Logo", value: DRIVER_TYPE_LOGO },
  { title: "Modbus", value: DRIVER_TYPE_MODBUS },
  { title: "CoreOS Panel", value: DRIVER_TYPE_COREOS_PANEL },
  //{ title: "RS 485", value: DRIVER_TYPE_RS485 },
  //{ title: "Philips Hue", value: DRIVER_TYPE_HUE },
];

export const connectionTypes = [
  { title: "TCP", value: CONNECTION_TCP },
  { title: "RTU", value: CONNECTION_RTU },
  { title: "RTU over TCP", value: CONNECTION_RTU_OVER_TCP },
];

export const connectors = [
  { title: "RS232", value: RS232 },
  { title: "RS485", value: RS485 },
];

export const baudrates = [
  300,
  600,
  1200,
  2400,
  4800,
  9600,
  19200,
  38400,
  57600,
  76800,
  115200,
  230400,
];

export function showIpAddress(driverType) {
  if (
    !driverType ||
    [DRIVER_TYPE_GPIO, DRIVER_TYPE_KNXTP, DRIVER_TYPE_RS485].indexOf(
      driverType
    ) >= 0
  )
    return false;

  if (driverType == DRIVER_TYPE_MODBUS) return false;

  return true;
}
export function showIpInputModbus(connectionType) {
  if (
    connectionType == CONNECTION_TCP ||
    connectionType == CONNECTION_RTU_OVER_TCP
  )
    return true;

  return false;
}

export function showBaudrate(driverType, connectionType) {
  return driverType == DRIVER_TYPE_MODBUS && connectionType == CONNECTION_RTU;
}

export function showConnector(driverType, connectionType) {
  return driverType == DRIVER_TYPE_MODBUS && connectionType == CONNECTION_RTU;
}

export function showConnectionType(driverType) {
  if (driverType == DRIVER_TYPE_MODBUS) return true;

  return false;
}

export function driverTitle(driverType) {
  const type = driverTypes.find((type) => type.value === driverType);
  if (type) return type.title;
  return driverType;
}

export function getPort(driverType) {
  if (driverType == DRIVER_TYPE_LOGO) return 510;
  else if (driverType == DRIVER_TYPE_KNXIP) return 3671;
  else if (driverType == DRIVER_TYPE_COOLMASTER) return 10102;
  return 0;
}
