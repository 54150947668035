<template>
  <div class="drivers">

    <app-navigation :title="$t('drivers.drivers')" actionButtonIcon="fa fa-plus"
      :actionButtonDesc="$t('drivers.new-driver-add')" @action="$router.push({ name: 'DriverAdd' })"></app-navigation>


    <el-table :data="$store.getters.drivers" height="500" v-loading="loading" header-row-class-name="table-header"
      row-class-name="table-rows">

      <el-table-column prop="driverType" :label="$t('drivers.type')" width="300">

        <template slot-scope="scope"> {{ driverTitle(scope.row.driverType) }} </template>

      </el-table-column>

      <el-table-column :label="$t('drivers.description')" width="400">

        <template slot-scope="scope">{{ getDescription(scope.row.ipAddress, scope.row.port, scope.row) }}</template>

      </el-table-column>


      <el-table-column align="right" :label="$t('drivers.actions')">

        <template slot-scope="scope">
          <span v-show="needRefresh(scope.row.driverType)" @click="refreshDriver(scope.row)"><i
              class="fas fa-sync"></i></span>
          <span v-show="isEditable(scope.row.driverType)"
            @click="$router.push({ name: 'DriverEdit', params: { id: scope.row.id } })"><i class="far fa-edit"></i></span>
          <span @click="removeDriver(scope.row)"><i class="far fa-trash-alt"></i></span>
        </template>

      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { driverTitle } from "@/utils/driver-utils";
import Navigation from '@/components/Navigation';
import { showIpAddress } from "@/utils/driver-utils";
import { initializeWebSocket } from "@/utils/socket-utils"
import { DRIVER_TYPE_MODBUS, CONNECTION_RTU, RS485, DRIVER_TYPE_COREOS_PANEL } from "@/utils/driver-utils";
export default {
  name: "drivers",

  components: {
    'app-navigation': Navigation
  },

  data: function () {
    return {
      loading: true,
    }
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    isEditable: function (driverType) {
      return showIpAddress(driverType) || driverType == DRIVER_TYPE_MODBUS
    },
    needRefresh: function (driverType) {
      return [DRIVER_TYPE_COREOS_PANEL].indexOf(driverType) > -1;
    },
    driverTitle,

    refreshDriver(driver) {
      this.$store.dispatch('refreshDriver', driver.id).then(response => {
        this.$message({
          message: this.$t(response.message),
          type: 'success'
        })
      }).catch(reason => {
        this.$message({
          message: this.$t(reason),
          type: 'error'
        })
      })
    },

    getDescription(ipAddress, port, row) {
      if (row.driverType === DRIVER_TYPE_MODBUS) {
        if (row.extraData) {
          try {
            let data = JSON.parse(row.extraData);
            if (data.connectionType && data.connectionType == CONNECTION_RTU) {
              return data.connector === RS485 ? "RS485" : "RS232"
            }
            return ipAddress ? (ipAddress + ":" + port) : "-"
          }
          catch (e) {
            return "-"
          }
        }
      }
      else
        return ipAddress ? (ipAddress + ":" + port) : "-"
    },

    removeDriver(driver) {
      this.$confirm(this.$t('alert.message.confirm-delete'), this.$t('alert.title.warning'), {
        confirmButtonText: this.$t('alert.button.ok'),
        cancelButtonText: this.$t('alert.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('removeDriver', driver.id).then(response => {
          this.$message({
            message: this.$t(response.message),
            type: 'success'
          })
        }).catch(reason => {
          this.$message({
            message: this.$t(reason),
            type: 'error'
          })
        })
      })
    },

    async fetchData() {
      try {
        await this.$store.dispatch("getDrivers");
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },

  created() {
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }
}
</script>

<style scoped>
@import '../../style/drivers.css';

span i {
  font-size: large;
  padding: 10px;
  cursor: pointer;
}

span i:hover {
  background-color: #00BFD7;
  border-radius: 5px;
  color: white;
}

span i:active {
  box-shadow: 0px 0px 10px #00BFD7;
  border-radius: 5px;
}
</style>