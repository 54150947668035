var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drivers" },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("drivers.drivers"),
          actionButtonIcon: "fa fa-plus",
          actionButtonDesc: _vm.$t("drivers.new-driver-add")
        },
        on: {
          action: function($event) {
            return _vm.$router.push({ name: "DriverAdd" })
          }
        }
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.$store.getters.drivers,
            height: "500",
            "header-row-class-name": "table-header",
            "row-class-name": "table-rows"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "driverType",
              label: _vm.$t("drivers.type"),
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.driverTitle(scope.row.driverType)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("drivers.description"), width: "400" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.getDescription(
                          scope.row.ipAddress,
                          scope.row.port,
                          scope.row
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: _vm.$t("drivers.actions") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.needRefresh(scope.row.driverType),
                            expression: "needRefresh(scope.row.driverType)"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.refreshDriver(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-sync" })]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isEditable(scope.row.driverType),
                            expression: "isEditable(scope.row.driverType)"
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "DriverEdit",
                              params: { id: scope.row.id }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-edit" })]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeDriver(scope.row)
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-trash-alt" })]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }